var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal" },
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.title,
            centered: "",
            width: 1000,
            okText: "确认",
            cancelText: "关闭"
          },
          on: { ok: _vm.then, cancel: _vm.cancel },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("a-icon", {
                style: { fontSize: "30px" },
                attrs: { type: "left-circle" },
                on: { click: _vm.back }
              }),
              _c(
                "div",
                { staticClass: "img-content" },
                [
                  _c("vueCropper", {
                    ref: "cropper",
                    attrs: {
                      img: _vm.list[_vm.current],
                      outputSize: _vm.option.size,
                      outputType: _vm.option.outputType,
                      info: true,
                      full: _vm.option.full,
                      canMove: _vm.option.canMove,
                      canMoveBox: _vm.option.canMoveBox,
                      original: _vm.option.original,
                      autoCrop: _vm.option.autoCrop,
                      fixed: _vm.option.fixed,
                      fixedNumber: _vm.option.fixedNumber,
                      centerBox: _vm.option.centerBox,
                      infoTrue: _vm.option.infoTrue,
                      fixedBox: _vm.option.fixedBox
                    }
                  })
                ],
                1
              ),
              _c("a-icon", {
                style: { fontSize: "30px" },
                attrs: { type: "right-circle" },
                on: { click: _vm.next }
              })
            ],
            1
          ),
          _c("div", { staticClass: "set-btn" }, [
            _c(
              "div",
              [
                [1, 3].includes(_vm.cutPicType)
                  ? _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.cropperImg(1)
                          }
                        }
                      },
                      [_vm._v("切正面")]
                    )
                  : _vm._e(),
                [2, 3].includes(_vm.cutPicType)
                  ? _c(
                      "a-button",
                      {
                        attrs: { type: "error" },
                        on: {
                          click: function($event) {
                            return _vm.cropperImg(2)
                          }
                        }
                      },
                      [_vm._v("切反面")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("div", { staticClass: "forms" }, [
            [1, 3].includes(_vm.cutPicType)
              ? _c("div", { staticClass: "forms-left" }, [
                  _c("span", [_vm._v("剪切正面预览：")]),
                  _c("div", { staticClass: "forms-img" }, [
                    !_vm.bottles.front
                      ? _c("span", [_vm._v("请裁剪正面")])
                      : _vm._e(),
                    _vm.bottles.front
                      ? _c("img", {
                          attrs: { src: _vm.bottles.front, alt: "" }
                        })
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            [2, 3].includes(_vm.cutPicType)
              ? _c("div", { staticClass: "forms-right ml-20" }, [
                  _c("span", [_vm._v("剪切反面预览:")]),
                  _c("div", { staticClass: "forms-img" }, [
                    !_vm.bottles.contrary
                      ? _c("span", [_vm._v("请裁剪反面")])
                      : _vm._e(),
                    _vm.bottles.contrary
                      ? _c("img", {
                          attrs: { src: _vm.bottles.contrary, alt: "" }
                        })
                      : _vm._e()
                  ])
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue'
import App from './App.vue'
import router from './route/index'
import axios from './axios/index'
import store from './store/index'
import unitls from './untils/index'
import Componests from './components/index'
import echarts from 'echarts'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import Print from '@/untils/print'
import VueLazyload from "vue-lazyload"

Vue.config.productionTip = false

Vue.use(Antd)
Vue.use(Print)
Vue.use(Componests)

Vue.prototype.axios = axios
Vue.prototype.$echarts = echarts
Vue.prototype.untils = unitls
Vue.prototype.$eventBus = new Vue()
Vue.use(VueLazyload, {
    // 设置懒加载时的占位图
    loading: require('../src/assets/product-default.png'),
    // 设置图片加载失败时的占位图
    error: require('../src/assets/product-default.png'),
    preLoad: 1
});
Vue.directive('drag-modal', (el, bindings, vnode) => {
  Vue.nextTick(() => {
      let { visible, destroyOnClose } = vnode.componentInstance
      if (!visible) return
      let modal = el.getElementsByClassName('ant-modal')[0]

      let header = el.getElementsByClassName('ant-modal-header')[0]
      let footer = el.getElementsByClassName('ant-modal-footer')[0];
      let modelContent = el.getElementsByClassName('ant-modal-content'[0]);
      let left = 0
      let top = 0
      // 未定义 destroyOnClose 时，dom未被销毁，关闭弹窗再次打开，弹窗会停留在上一次拖动的位置
      if (!destroyOnClose) {
          left = modal.left || 0
          top = modal.top || 0
      }
      footer.onmousedown = e => {
          let startX = e.clientX;
          let startY = e.clientY;
          header.left = header.offsetLeft;
          header.top = header.offsetTop;

          el.onmousemove = event => {
              let endX = event.clientX;
              let endY = event.clientY;
                // 左右拖拽的距离
              modal.left = header.left + (endX - startX) + left; 
              //上下拖拽的距离
              modal.top = header.top + (endY - startY) + top;
              //屏幕边界X
              const screenX = (document.body.offsetWidth + 17 - modal.offsetWidth) / 2
              //屏幕边界Y
              const screenY = (document.body.offsetHeight - modal.offsetHeight) / 2
      
              //判断拖拽是否超出屏幕  X
              // if (Math.abs(modal.left) > screenX) {
              //     if (modal.left < 0) {
              //         modal.style.left = -screenX + 'px'
              //     }
              //     if (modal.left > 0) {
              //         modal.style.left = screenX + 'px'
              //     }

              // } else {
              //     modal.style.left = modal.left + 'px'
              // }
              modal.style.left = modal.left + 'px'
              // //判断拖拽是否超出屏幕  Y
              // if (Math.abs(modal.top) > screenY) {
              //     if (modal.top < 0) {
              //         modal.style.top = -screenY + 'px'
              //     }
              //     if (modal.top > 0) {
              //         modal.style.top = screenY + 'px'
              //     }

              // } else {
              //     modal.style.top = modal.top + 'px'
              // }
              modal.style.top = modal.top + 'px'

          }
          el.onmouseup = event => {
              left = modal.left
              top = modal.top
              el.onmousemove = null;
              el.onmouseup = null;
              header.releaseCapture && header.releaseCapture();
          }
          header.setCapture && header.setCapture();
          return false
      }
      header.onmousedown = e => {
        let startX = e.clientX;
        let startY = e.clientY;
        header.left = header.offsetLeft;
        header.top = header.offsetTop;

        el.onmousemove = event => {
            let endX = event.clientX;
            let endY = event.clientY;
              // 左右拖拽的距离
            modal.left = header.left + (endX - startX) + left; 
            //上下拖拽的距离
            modal.top = header.top + (endY - startY) + top;
            //屏幕边界X
            const screenX = (document.body.offsetWidth + 17 - modal.offsetWidth) / 2
            //屏幕边界Y
            const screenY = (document.body.offsetHeight - modal.offsetHeight) / 2
    
            //判断拖拽是否超出屏幕  X
            // if (Math.abs(modal.left) > screenX) {
            //     if (modal.left < 0) {
            //         modal.style.left = -screenX + 'px'
            //     }
            //     if (modal.left > 0) {
            //         modal.style.left = screenX + 'px'
            //     }

            // } else {
            //     modal.style.left = modal.left + 'px'
            // }
            modal.style.left = modal.left + 'px'
            // //判断拖拽是否超出屏幕  Y
            // if (Math.abs(modal.top) > screenY) {
            //     if (modal.top < 0) {
            //         modal.style.top = -screenY + 'px'
            //     }
            //     if (modal.top > 0) {
            //         modal.style.top = screenY + 'px'
            //     }

            // } else {
            //     modal.style.top = modal.top + 'px'
            // }
            modal.style.top = modal.top + 'px'

        }
        el.onmouseup = event => {
            left = modal.left
            top = modal.top
            el.onmousemove = null;
            el.onmouseup = null;
            header.releaseCapture && header.releaseCapture();
        }
        header.setCapture && header.setCapture();
        return false
    }
  })
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style lang='scss'>
@import "assets/base.css";
html,body,#app {width:100%;height:100%;}
.ant-pagination-simple-pager > input {
  pointer-events: none !important;
}
.ant-cascader-menus-content > ul {
  height: 400px !important;
}
/* 针对webkit内核浏览器 */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* 针对Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>


import Vue from 'vue'
import previewImg from './index.vue'

const previewImgConstructor = Vue.extend(previewImg)

const Content = ({
    current,
    list,
    baseUrl,
    baseImgField,
    baseTitleField,
    showMute=true,
    showBigCoinName,
    showBigCoinStatus = false,
    showShellStatus= false,
    showImageByListId
})=>{
    const instance = new previewImgConstructor({
        data:{
            currentIndex:current || 0,
            list:list||[],
            baseUrl:baseUrl||'',
            baseImgField:baseImgField||'',
            baseTitleField:baseTitleField||'',
            showMute:showMute,
            showBigCoinName: showBigCoinName || '',
            showBigCoinStatus: showBigCoinStatus,
            showShellStatus: showShellStatus,
            showImageByListId: showImageByListId || ''
        }
    })
    instance.vm = instance.$mount()
    instance.vm.show = true
    instance.dom = instance.vm.$el
    document.body.appendChild(instance.vm.$el) // 将dom插入body
    instance.vm.$on('close', () => {
        instance.vm.show = false
        document.body.removeChild(instance.vm.$el)
    })
    return instance.vm
}
export default {
    install: Vue => {
      Vue.prototype.$previewImg = Content // 组件暴露出去，并挂载在Vue的prototype上
    }
}



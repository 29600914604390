export default [
    {
        path:'/cms/currencymannage/versionClassfy',
        name:'versionClassfy',
        component: resolve => require(['@/views/cmsPage/currencyMannage/versionClassifyManage/index'], resolve)
    },
    {
        path:'/cms/currencymannage',
        name:'CurrencyMannage',
        component: resolve => require(['@/views/cmsPage/currencyMannage/index'], resolve)
    },
    {
        path:'/cms/currencymannage/small',
        name:'CurrencySmallMannage',
        component: resolve => require(['@/views/cmsPage/currencyMannage/small'], resolve)
    },
    {
        path:'/cms/currencymannage/dynasty',
        name:'Dynasty',
        component: resolve => require(['@/views/cmsPage/currencyMannage/dynasty'], resolve)
    },
    {
        path:'/cms/currencymannage/emperor',
        name:'Dynasty',
        component: resolve => require(['@/views/cmsPage/currencyMannage/emperor'], resolve)
    },
    {
        path:'/cms/currencymannage/bottle',
        name:'CurrencyBottle',
        component: resolve => require(['@/views/cmsPage/currencyMannage/bottle'], resolve)
    },
    {
        path:'/cms/currencymannage/priceStatistics',
        name:'priceStatistics',
        component: resolve => require(['@/views/cmsPage/currencyMannage/priceStatistics'], resolve)
    },
    {
        path:'/cms/currencymannage/versionScoreManage',
        name:'versionScoreManage',
        component: resolve => require(['@/views/cmsPage/currencyMannage/versionScoreManage'], resolve)
    },
    {
        path:'/cms/currencymannage/dollat',
        name:'Dollat',
        component: resolve => require(['@/views/cmsPage/currencyMannage/dollat'], resolve)
    },
    {
        path:'/cms/currencymannage/slivermarketcharts',
        name:'SilverMarketCharts',
        component: resolve => require(['@/views/cmsPage/currencyMannage/silverMarketCharts'], resolve)
    },
    {
        path:'/cms/currencymannage/tradingmanage',
        name:'TradingManage',
        component: resolve => require(['@/views/cmsPage/currencyMannage/tradingManage'], resolve)
    },
]
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tips", attrs: { id: "tips98" } },
    [
      _c("a-icon", {
        staticStyle: { position: "absolute", right: "10px" },
        attrs: { type: "close-circle" },
        on: { click: _vm.close }
      }),
      _vm._l(_vm.list, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            on: {
              click: function($event) {
                return _vm.untils.copy_text(item.words)
              }
            }
          },
          [_vm._v(_vm._s(item.words))]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import Vue from 'vue'
import outsizeTips from './index.vue'

const outsizeTipsElement = Vue.extend(outsizeTips)

const Content = ({list})=>{
    const instance = new outsizeTipsElement({
        data:{
            list:list||[],
        }
    })
    instance.vm = instance.$mount()
    instance.vm.show = true
    instance.dom = instance.vm.$el
    document.body.appendChild(instance.vm.$el) // 将dom插入body
    instance.vm.$on('close', () => {
        instance.vm.show = false
        document.body.removeChild(instance.vm.$el)
    })
    return instance.vm
}
export default {
    install: Vue => {
      Vue.prototype.$outsizeTips = Content // 组件暴露出去，并挂载在Vue的prototype上
    }
}



export default [
    /** 拍品管理相关 */
    {
        path:'/cms/currencymannage/auctiongoods',
        name:'auctionList',
        title: '拍品管理',
        component: resolve => require(['@/views/cmsPage/productManage/auctionAboutList/auctionList/index.vue'], resolve)
    },
    {
        path:'/cms/currencymannage/simpleauctiongoods',
        name:'auctionSimpleList',
        title: '拍品审核',
        component: resolve => require(['@/views/cmsPage/productManage/auctionAboutList/auctionSimpleList/index.vue'], resolve)
    },
    /** 商品标签管理 */
    {
        path:'/cms/producttag/tagmanage',
        name:'tagManage',
        component: resolve => require(['@/views/cmsPage/productManage/productTagList/index'], resolve)
    },
    /** 一口价管理 */
    {
        path:'/cms/currencymannage/goods',
        name:'currencyGoodsList',
        component: resolve => require(['@/views/cmsPage/productManage/fixedPriceProductList/index'], resolve)
    },
    /** 假盒子管理相关 */
    {
        path:'/cms/boxsham/managetypelist',
        name:'managetypelist',
        title: '假币分组',
        component: resolve => require(['@/views/cmsPage/productManage/boxShamAboutList/boxShamTypeList/index'], resolve)
    },
    {
        path:'/cms/boxbase/managelist',
        name:'managelistBox',
        title: '假币列表管理',
        component: resolve => require(['@/views/cmsPage/productManage/boxShamAboutList/boxShamList/index'], resolve)
    },
    {
        path:'/cms/productperiod/periodlist',
        name:'productperiod',
        title: '场次管理',
        component: resolve => require(['@/views/cmsPage/productManage/productSquare/index'], resolve)
    },
    {
        path:'/cms/productreport/listmanage',
        name:'productreport',
        title: '商品举报',
        component: resolve => require(['@/views/cmsPage/productManage/userReportAuctionList/index'], resolve)
    },
]
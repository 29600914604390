import Vue from 'vue'
import CoverModal from './index.vue'

const CoverModalElement = Vue.extend(CoverModal)

const Content = ({title,list,then,cancel, cutPicType})=>{
    const instance = new CoverModalElement({
        data:{
            title:title,
            list:list||[],
            show:false,
            // 1, 正面裁剪，2，反面裁剪，3，正反面必须裁剪
            cutPicType: cutPicType || 3
        }
    })
    instance.vm = instance.$mount()
    instance.vm.show = true
    instance.dom = instance.vm.$el
    document.body.appendChild(instance.vm.$el) // 将dom插入body
    instance.vm.$on('then', (row) => {
        if (typeof then === 'function') {
            then(row)
        }
        instance.dom.remove();
        instance.vm.$destroy()
    })
    instance.vm.$on('cancel', () => {
        if (typeof cancel === 'function') {
            cancel(instance)
        }
        instance.dom.remove();
        instance.vm.$destroy()
    })
    return instance.vm
}
export default {
    install: Vue => {
      Vue.prototype.coverImg = Content // 组件暴露出去，并挂载在Vue的prototype上
    }
}



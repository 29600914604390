import axios from '../axios/index'
const actions = {
    async updataMenu(state){
        const res = await axios('/dq_admin/menu/selectMenu')
        if(res.status == 200){
            const {data} = res
            state.commit('updateMenu',data)
            state.commit('setBreads')
        }
    }
}

export default actions
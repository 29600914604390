//此处注册全局组件

import PreviewImg from './previewImg/index'

import CoverImg from './Modal/coverModal/index'
import OutsizeTips from './outsizeTips/index'
import Loading from './loading/index'

export default (Vue)=>{

  Vue.use(PreviewImg)
  Vue.use(CoverImg)
  Vue.use(OutsizeTips)
  Vue.use(Loading)
}
import { message } from 'ant-design-vue';
import axios from '../axios/index'
export default{
    setParams(params){
        let strs = ''
        for(let i in params){
            strs += params[i] ? `${i}=${params[i]}&` : ''
        }
        const return_strs = strs.substr(0,strs.length-1)||''
        return return_strs
    },
    filterOption(e,option){
        const in_array = option.componentOptions.children
        if(in_array[0].children&&in_array[0].children.length>0){
            const {attrs} = in_array[0].children[1].data
            return (
                attrs.text.toLowerCase().indexOf(e.toLowerCase().trim()) >= 0
                );
        }else{
            return (
                in_array[0].text.toLowerCase().indexOf(e.toLowerCase().trim()) >= 0
                );
        }
    },
    copy_text(text){
        let oInput = document.createElement('input')
        oInput.value = text
        document.body.appendChild(oInput)
        oInput.select()
        document.execCommand("Copy")
        oInput.remove()
        message.success('已复制进剪切板')
    },

    async pasteImg(e,type,callback,row) {
        const cbd = e.clipboardData;
          const ua = window.navigator.userAgent;
          // 如果是 Safari 直接 return
          if ( !(e.clipboardData && e.clipboardData.items) ) {
            return ;
          }
          if(cbd.items && cbd.items.length === 2 && cbd.items[0].kind === "string" && cbd.items[1].kind === "file" &&
              cbd.types && cbd.types.length === 2 && cbd.types[0] === "text/plain" && cbd.types[1] === "Files" &&
              ua.match(/Macintosh/i) && Number(ua.match(/Chrome\/(\d{2})/i)[1]) < 49){
              return;
          }
          for(let i = 0; i < cbd.items.length; i++) {
          let item = cbd.items[i];
          if(item.kind == "file"){
            // blob 就是从剪切板获得的文件，可以进行上传或其他操作
            const blob = item.getAsFile();
            if (blob.size === 0) {
                return;
            }
            const formData = new FormData()
            formData.append('file',blob)
            axios({
                method:'post',
                url:'/file/upload',
                data:formData,
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).then(res=>{
                const {data} = res
                if(type){
                    callback(type,data,row||{})
                }
            })
          }
        }
    },
    downImg(url,filename){              
        let img = new Image();
        img.onload=e=>{
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;   
            if(canvas.getContext){
                let context = canvas.getContext('2d')
                context.drawImage(img,0,0,img.width,img.height)//绘制图纸
                let downUrl = canvas.toDataURL('image/png')
                let link = document.createElement('a');
                link.href = downUrl;
                link.style.display = 'none';
                link.download = filename||'海报.png'; // 文件名称
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href); //释放URL对象
                document.body.removeChild(link);
            }
        }; 
        // img.src = this.$store.state.ip+url
        img.src = `${url}?response-content-type=application/octet-stream`
        img.crossOrigin = "anonymous"
    },
    throttle(func,wait){
        let timer = null;
        let fn = function(){
          if(!timer){
            let args = Array.from(arguments);
            timer = setTimeout(()=>{
              func.apply(this,args);
              timer = null
            },wait)
          }
        }
        return fn
    },
    randomString(len) {
        len = len || 32;
        const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
        const maxPos = $chars.length;
        let pwd = '';
        for (let i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },
    getBase64(imgUrl) {
        return new Promise((resolve, reject) => {
            if (!imgUrl) {
                reject('请传入imgUrl内容')
            }
            if (/\.(png|jpe?g|gif|svg|webp)(\?.*)?$/.test(imgUrl)) {
                window.URL = window.URL || window.webkitURL
                var xhr = new XMLHttpRequest()
                xhr.open('get', imgUrl, true)
                xhr.responseType = 'blob'
                xhr.onload = function() {
                    if (this.status == 200) {
                        // 得到一个blob对象
                        var blob = this.response
                        const oFileReader = new FileReader()
                        oFileReader.onloadend = function(e) {
                            const base64 = e.target.result
                            resolve(base64 || '')
                        }
                        oFileReader.readAsDataURL(blob)
                    }
                }
                xhr.send()
            } else {
                // 非图片地址
                reject('非(png/jpe?g/gif/svg等)图片地址')
            }
        })
    },
}
export default [
  {
    path: '/cms/silver/pcgswaiting',
    name: 'pcgswaiting',
    component: resolve => require(['@/views/cmsPage/dollarManager/unConfirmedPCGS/index.vue'], resolve)
  },
  {
    path: '/cms/silver/gbwaiting',
    name: 'pcgswaiting',
    component: resolve => require(['@/views/cmsPage/dollarManager/unConfirmedGB/index.vue'], resolve)
  },
  {
    path: '/cms/silver/confirm', 
    name: 'confirm', 
    component: resolve => require(['@/views/cmsPage/dollarManager/confirmed/Index.vue'], resolve)
  },
  {
    path: '/cms/silver/vipmanage', 
    name: 'vipmanage', 
    component: resolve => require(['@/views/cmsPage/dollarManager/vipmanage/index.vue'], resolve)
  },
  {
    path: '/cms/silver/agentmanage', 
    name: 'agentmanage', 
    component: resolve => require(['@/views/cmsPage/dollarManager/agentmanage/index.vue'], resolve)
  },
]
export default [
    {
        path: '/cms/coinBook',
        name: 'coinBook',
        component: resolve => require(['@/views/cmsPage/coinBookManage/ImgStoreManage/index'], resolve)
    },
    {
        path:'/cms/tuku/nature',
        name:'natureManage',
        component: resolve => require(['@/views/cmsPage/coinBookManage/natureManage/index'], resolve)
    },
    {
        path:'/cms/tuku/customcategory',
        name:'customcategory',
        component: resolve => require(['@/views/cmsPage/dqPlatform/customCategory'], resolve)
    },
    {
        path:'/cms/tuku/classifyversion',
        name:'classifyversion',
        component: resolve => require(['@/views/cmsPage/coinBookManage/classifyVersion/index.vue'], resolve)
    },
    {
        path:'/cms/tuku/reviewlist',
        name:'reviewlist',
        component: resolve => require(['@/views/cmsPage/coinBookManage/reviewManage/index.vue'], resolve)
    },
]
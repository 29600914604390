<template>
  <div class="modal">
    <a-modal :title='title' centered :width='1000' okText='确认' cancelText='关闭' v-model="show" @ok='then' @cancel='cancel'>
      <div class="content">
        <a-icon @click="back" :style="{ fontSize: '30px',}" type="left-circle" />
        <div class="img-content">
<!--           <img :src="list[current]" alt="">-->
          <vueCropper
              ref="cropper"
              :img="list[current]"
              :outputSize="option.size"
              :outputType="option.outputType"
              :info="true"
              :full="option.full"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :autoCrop="option.autoCrop"
              :fixed="option.fixed"
              :fixedNumber="option.fixedNumber"
              :centerBox="option.centerBox"
              :infoTrue="option.infoTrue"
              :fixedBox="option.fixedBox"
          ></vueCropper>
        </div>
        <a-icon @click="next" :style="{ fontSize: '30px',}" type="right-circle" />
      </div>
      <div class="set-btn">
        <div>
          <a-button @click="cropperImg(1)" type='primary' style="margin-right:10px;" v-if="[1, 3].includes(cutPicType)">切正面</a-button>
          <a-button @click="cropperImg(2)" type='error' v-if="[2, 3].includes(cutPicType)">切反面</a-button>
        </div>
      </div>
      <div class="forms">
        <div class="forms-left" v-if="[1, 3].includes(cutPicType)">
          <span>剪切正面预览：</span>
          <div class="forms-img">
            <span v-if="!bottles.front">请裁剪正面</span>
            <img v-if="bottles.front" :src="bottles.front" alt="">
          </div>
        </div>
        <div class="forms-right ml-20" v-if="[2, 3].includes(cutPicType)">
          <span>剪切反面预览:</span>
          <div class="forms-img">
            <span v-if="!bottles.contrary">请裁剪反面</span>
            <img v-if="bottles.contrary" :src="bottles.contrary" alt="">
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {VueCropper}  from 'vue-cropper'
export default {
  data(){
    return {
      current:0,
      bottles:{
        front:'',
        contrary:''
      },
      baseList: [],
      option: {
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1,1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
    }
  },
  components:{
    VueCropper
  },
  computed: {
  },
  created() {
  },
  methods:{
    // 将网络图片转换成base64格式
    transBase64FromImage(image) {
      let canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      let ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, image.width, image.height)
      // 可选其他值 image/jpeg
      return canvas.toDataURL('image/jpeg')
    },
    // 设置需要展示的图片  base64
    setImgBase64(src, callback) {
      let _this = this
      let image = new Image()
      // 处理缓存
      image.src = src
      // 支持跨域图片
      image.crossOrigin = '*'
      image.onload = function() {
        let base64 = _this.transBase64FromImage(image)
        callback && callback(base64)
      }
    },
    then(){
      if (this.cutPicType === 3) {
        if (this.bottles.front && this.bottles.contrary) {
          this.$emit('then',this.bottles)
        } else {
          this.$message.error('必须上传正反面裁剪图')
        }
      } else {
        if (this.bottles.contrary || this.bottles.front) {
          this.$emit('then',this.bottles)
        } else {
          this.$message.error('必须上传正面或反面裁剪图')
        }
      }
    },
    cancel(){
      this.$emit('cancel',this)
    },
    cropperImg(type){
      this.$refs.cropper.getCropBlob((data) => {
        const formData = new FormData()
        formData.append('file',data)
        this.axios({
          method:'post',
          url:'/file/upload',
          data:formData,
          headers:{
            'Content-Type':'multipart/form-data',
          }
        }).then(res=>{
          const {data} = res
          type == 1 ? this.bottles.front = data : this.bottles.contrary = data
        })
      })
    },
    next(){
      if(this.current>=(this.list.length-1)){
        return this.current = 0
      }
      this.current+=1
    },
    back(){
      if(this.current == 0){
        return this.current = this.list.length-1
      }
      this.current -=1
    },
  },

}
</script>

<style lang="scss" scoped>
.forms-left,
.forms-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .forms-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background-color: #cccccc;
    color: #fff;
    img {
      width: 100%;
      height: 100%;
    }
  }
  span {
    font-weight: bold;
  }
}
.content{display:flex;justify-content: space-between;align-items: center;
    .img-content{flex:1;height:400px;display:flex;justify-content:center;align-items:center;width:auto;
        img{max-width:700px;max-height:400px;}
    }
    
}
.set-btn{padding:10px 0;display: flex;justify-content:flex-end;}
.forms{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ml-20 {
  margin-left: 20px;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["preview-content", !_vm.out ? "prevent-out" : ""] },
    [
      _c("div", { staticClass: "header" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.list[_vm.currentIndex][_vm.baseTitleField] || ""))
        ]),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "flex-end",
              "align-items": "center"
            }
          },
          [
            _vm.showMute
              ? _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.openDetail(
                          _vm.baseUrl +
                            _vm.list[_vm.currentIndex][_vm.baseImgField]
                        )
                      }
                    }
                  },
                  [_vm._v("扫描图片")]
                )
              : _vm._e(),
            _c("a-icon", {
              staticClass: "close",
              attrs: { type: "close-circle" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "content",
          on: { mouseover: _vm.setShow, mouseleave: _vm.setHiden }
        },
        [
          _vm.showBigCoinName && _vm.showBigCoinStatus
            ? _c("div", { staticClass: "next-class coin-box left-10 top-20" }, [
                _vm._v(_vm._s(_vm.showBigCoinName))
              ])
            : _vm._e(),
          _vm.listBigCoinTextList[_vm.currentIndex] && _vm.showBigCoinStatus
            ? _c("div", { staticClass: "next-class coin-box left-10" }, [
                _vm._v(
                  _vm._s(_vm.listBigCoinTextList[_vm.currentIndex].frontOrBack)
                )
              ])
            : _vm._e(),
          _vm.isMore
            ? _c("a-icon", {
                staticClass: "next-class",
                staticStyle: { "font-size": "50px", color: "#fff" },
                attrs: { type: "left-circle" },
                on: { click: _vm.previous }
              })
            : _vm._e(),
          _c("div", { ref: "imgel", staticClass: "show-img" }, [
            _vm.maxWidth && _vm.maxHeight
              ? _c("img", {
                  style:
                    "max-width:" +
                    400 +
                    "px;" +
                    "max-height:" +
                    600 +
                    "px;" +
                    _vm.bigImgStyle,
                  attrs: {
                    src:
                      _vm.baseUrl +
                      _vm.list[_vm.currentIndex][_vm.baseImgField],
                    alt: ""
                  }
                })
              : _vm._e(),
            _vm.isFiringCheckMatch
              ? _c("img", {
                  staticClass: "match-img",
                  style: _vm.bigMatchImgStyle,
                  attrs: { src: _vm.icons.matchIcon, alt: "" }
                })
              : _vm._e()
          ]),
          _vm.isMore
            ? _c("a-icon", {
                staticClass: "right-class",
                staticStyle: { "font-size": "50px", color: "#fff" },
                attrs: { type: "right-circle" },
                on: { click: _vm.next }
              })
            : _vm._e(),
          _vm.showBigCoinStatus
            ? _c(
                "a-button",
                {
                  staticClass: "right-class coin-box-button right-10",
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.handlePushBigCoinBadCase(_vm.currentIndex)
                    }
                  }
                },
                [_vm._v("币种检测收集")]
              )
            : _vm._e(),
          _vm.showShellStatus
            ? _c(
                "a-button",
                {
                  staticClass: "right-class coin-box-button right-0",
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.handlePushShellBadCase(_vm.currentIndex)
                    }
                  }
                },
                [_vm._v("盒子币检测错误收集")]
              )
            : _vm._e(),
          _vm.showBigCoinStatus && _vm.listBigCoinTextList[_vm.currentIndex]
            ? _c("div", { staticClass: "right-class coin-box right-10" }, [
                _vm._v(
                  _vm._s(_vm.listBigCoinTextList[_vm.currentIndex].percent)
                )
              ])
            : _vm._e(),
          _vm.showBigCoinStatus && _vm.listBigCoinTextList[_vm.currentIndex]
            ? _c(
                "a-button",
                {
                  staticClass: "right-class coin-box-top-45 right-10",
                  on: {
                    click: function($event) {
                      return _vm.handleImgFrontOrBackBadeCase(_vm.currentIndex)
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.listBigCoinTextList[_vm.currentIndex].frontOrBack +
                        "收集"
                    )
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        _vm._l(_vm.list, function(item, index) {
          return _c("div", { key: index, staticClass: "preview" }, [
            _c("img", {
              class: _vm.currentIndex == index ? "in-footer" : "out-footer",
              staticStyle: { "max-width": "70px", "max-height": "70px" },
              attrs: {
                src: _vm.baseUrl + item[_vm.baseImgField] || item,
                alt: ""
              },
              on: {
                click: function($event) {
                  return _vm.current(index)
                }
              }
            })
          ])
        }),
        0
      ),
      _vm.isFiringCheckMatch
        ? _c("div", { staticClass: "swiper-switch-box-box" }, [
            true
              ? _c(
                  "div",
                  { staticClass: "jin-box" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.handleSaveInputValue }
                      },
                      [_vm._v("保存")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticClass: "ml-20",
                        attrs: { size: "small", type: "danger" },
                        on: { click: _vm.handleResetInputValue }
                      },
                      [_vm._v("重置")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "swiper-switch-box" }, [
              _c("div", { staticClass: "swiper-box-cell" }, [
                _c("div", [_vm._v("上下位移：")]),
                _c(
                  "div",
                  { staticStyle: { width: "50%" } },
                  [
                    _c("a-slider", {
                      attrs: { min: -100, max: 100 },
                      model: {
                        value: _vm.inputValueY,
                        callback: function($$v) {
                          _vm.inputValueY = $$v
                        },
                        expression: "inputValueY"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-input-number", {
                      staticStyle: { marginLeft: "16px" },
                      attrs: { size: "small", min: -100, max: 100 },
                      model: {
                        value: _vm.inputValueY,
                        callback: function($$v) {
                          _vm.inputValueY = $$v
                        },
                        expression: "inputValueY"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "swiper-box-cell" }, [
                _c("div", [_vm._v("左右位移：")]),
                _c(
                  "div",
                  { staticStyle: { width: "50%" } },
                  [
                    _c("a-slider", {
                      attrs: { min: -100, max: 100 },
                      model: {
                        value: _vm.inputValueX,
                        callback: function($$v) {
                          _vm.inputValueX = $$v
                        },
                        expression: "inputValueX"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-input-number", {
                      staticStyle: { marginLeft: "16px" },
                      attrs: { size: "small", min: -100, max: 100 },
                      model: {
                        value: _vm.inputValueX,
                        callback: function($$v) {
                          _vm.inputValueX = $$v
                        },
                        expression: "inputValueX"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "swiper-box-cell" }, [
                _c("div", [_vm._v("旋转：")]),
                _c(
                  "div",
                  { staticStyle: { width: "50%" } },
                  [
                    _c("a-slider", {
                      attrs: { min: 1, max: 360 },
                      model: {
                        value: _vm.inputValueR,
                        callback: function($$v) {
                          _vm.inputValueR = $$v
                        },
                        expression: "inputValueR"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-input-number", {
                      staticStyle: { marginLeft: "16px" },
                      attrs: { size: "small", min: 1, max: 360 },
                      model: {
                        value: _vm.inputValueR,
                        callback: function($$v) {
                          _vm.inputValueR = $$v
                        },
                        expression: "inputValueR"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "swiper-box-cell" }, [
                _c("div", [_vm._v("缩放：")]),
                _c(
                  "div",
                  { staticStyle: { width: "50%" } },
                  [
                    _c("a-slider", {
                      attrs: { min: 0.1, max: 3.1, step: 0.01 },
                      model: {
                        value: _vm.inputValueS,
                        callback: function($$v) {
                          _vm.inputValueS = $$v
                        },
                        expression: "inputValueS"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-input-number", {
                      staticStyle: { marginLeft: "16px" },
                      attrs: { size: "small", min: 0.1, max: 3.1, step: 0.01 },
                      model: {
                        value: _vm.inputValueS,
                        callback: function($$v) {
                          _vm.inputValueS = $$v
                        },
                        expression: "inputValueS"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "swiper-box-cell" }, [
                _c("div", [_vm._v("倾斜X轴：")]),
                _c(
                  "div",
                  { staticStyle: { width: "50%" } },
                  [
                    _c("a-slider", {
                      attrs: { min: -90, max: 90 },
                      model: {
                        value: _vm.inputValue3DX,
                        callback: function($$v) {
                          _vm.inputValue3DX = $$v
                        },
                        expression: "inputValue3DX"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-input-number", {
                      staticStyle: { marginLeft: "16px" },
                      attrs: { size: "small", min: -90, max: 90 },
                      model: {
                        value: _vm.inputValue3DX,
                        callback: function($$v) {
                          _vm.inputValue3DX = $$v
                        },
                        expression: "inputValue3DX"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "swiper-box-cell" }, [
                _c("div", [_vm._v("倾斜Y轴：")]),
                _c(
                  "div",
                  { staticStyle: { width: "50%" } },
                  [
                    _c("a-slider", {
                      attrs: { min: -90, max: 90 },
                      model: {
                        value: _vm.inputValue3DY,
                        callback: function($$v) {
                          _vm.inputValue3DY = $$v
                        },
                        expression: "inputValue3DY"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-input-number", {
                      staticStyle: { marginLeft: "16px" },
                      attrs: { size: "small", min: -90, max: 90 },
                      model: {
                        value: _vm.inputValue3DY,
                        callback: function($$v) {
                          _vm.inputValue3DY = $$v
                        },
                        expression: "inputValue3DY"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "swiper-box-cell" }, [
                _c("div", [_vm._v("倾斜Z轴：")]),
                _c(
                  "div",
                  { staticStyle: { width: "50%" } },
                  [
                    _c("a-slider", {
                      attrs: { min: -90, max: 90 },
                      model: {
                        value: _vm.inputValue3DZ,
                        callback: function($$v) {
                          _vm.inputValue3DZ = $$v
                        },
                        expression: "inputValue3DZ"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-input-number", {
                      staticStyle: { marginLeft: "16px" },
                      attrs: { size: "small", min: -90, max: 90 },
                      model: {
                        value: _vm.inputValue3DZ,
                        callback: function($$v) {
                          _vm.inputValue3DZ = $$v
                        },
                        expression: "inputValue3DZ"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "check-box-match" },
        [
          _c(
            "a-checkbox",
            {
              attrs: { checked: _vm.isFiringCheckMatch },
              on: { change: _vm.handleChangeFiringCheckMatch }
            },
            [_vm._v("是否启用钱币校准")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default [
    {
        path: '/cms/dqquality/operate',
        name: 'operate',
        component: resolve => require(['@/views/cmsPage/checkManage/operate/index'], resolve)
    },
    {
        path: '/cms/dqquality/recordlist',
        name: 'recordlist',
        component: resolve => require(['@/views/cmsPage/checkManage/recordlist/index'], resolve)
    },
    {
        path: '/cms/identifyconfirm/resultabclist',
        name: 'resultabclist',
        component: resolve => require(['@/views/cmsPage/checkManage/resultList/index'], resolve)
    },
    {
        path: '/cms/identifyconfirm/resultdlist',
        name: 'resultdlist',
        component: resolve => require(['@/views/cmsPage/checkManage/DRateResultList/index'], resolve)
    },
    {
        path: '/cms/dqquality/returnlist',
        name: 'returnlist',
        component: resolve => require(['@/views/cmsPage/checkManage/returnList/index'], resolve)
    },
    {
        path: '/cms/personRatingApply/dlist',
        name: 'dlist',
        component: resolve => require(['@/views/cmsPage/checkManage/dList/index'], resolve)
    },
    {
        path: '/cms/rating/manage',
        name: 'ratingManage',
        component: resolve => require(['@/views/cmsPage/checkManage/ratingManage/index'], resolve)
    }
]
export default [
  {
    path: '/cms/guqianbi/spider',
    name: 'spider',
    component: resolve => require(['@/views/cmsPage/coinManage/spider/index.vue'], resolve)
  },
  {
    path: '/cms/guqianbi/goods',
    name: 'goods',
    component: resolve => require(['@/views/cmsPage/coinManage/goods/index.vue'], resolve)
  },
  {
    path:'/cms/dqplatform/recordsearchhistory',
    name:'recordhistory',
    component: resolve => require(['@/views/cmsPage/coinManage/queryHistory/index.vue'], resolve)
  },
  {
    path:'/cms/searchword/config',
    name:'searchWordConfig',
    component: resolve => require(['@/views/cmsPage/coinManage/searchWordConfig/index.vue'], resolve)
  },
]
<template>
    <div id='tips98' class="tips">
        <a-icon @click="close" style="position:absolute;right:10px;" type="close-circle" />
        <div @click="untils.copy_text(item.words)" v-for="(item,index) in list" :key="index">{{item.words}}</div>
    </div>
</template>

<script>
export default {
    data(){
        return {}
    },
    methods:{
        close(){
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.tips{width:200px;height:auto;overflow-y:auto;background:rgba(0,0,0,.5);position:fixed;top:0;left:0;border-radius:5px;
    padding:10px 20px;color:#fff;cursor:pointer;
    div{margin-top:10px}    
}
</style>
import VueRoute from 'vue-router'
import Vue from 'vue'
import routerMap from './routerMap/index'

const routerPush = VueRoute.prototype.push
VueRoute.prototype.push = function push(location) {//防止重新加载相同的路由时控制台报错
  return routerPush.call(this, location).catch(error=> error)
}

Vue.use(VueRoute)

const routes = [
    {
      path:'',name:'login',meta:{title:"星辰大海"},
      component: resolve => require(['@/views/login/login'], resolve),
    },
    {
      path:'/cms',name:'cms',meta:{},children:routerMap,
      component: resolve => require(['@/views/cms'], resolve)
    }
]

const router = new VueRoute({
    // 配置路由和组件之间的应用关系
    routes,
    mode: 'hash',
    linkActiveClass: 'active'
})
router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title == undefined?'斗泉运营管理后台':to.meta.title

    if(to.path === '/'){
      next();
    }else{//此处可以做一些逻辑判断，例如权限，token验证
      let token = localStorage.getItem('token');
      if(!token) {
        next('/');
      }else{
        next();
      }
    }
});

export default router
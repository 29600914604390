export default [
  {
    path: '/cms/versionManage/duibanquestionpool',
    name: 'duibanquestionpool',
    component: resolve => require(['@/views/cmsPage/versionManage/duibanPool/index.vue'], resolve)
  },
  {
    path: '/cms/versionManage/pretreatmentMark',
    name: 'pretreatmentMark',
    component: resolve => require(['@/views/cmsPage/versionManage/pretreatmentMark/index.vue'], resolve)
  },
  {
    path: '/cms/dqmanage/waitratingsplideranswer',
    name: 'waitratingsplideranswer',
    component: resolve => require(['@/views/cmsPage/versionManage/waitratingsplideranswer/index.vue'], resolve)
  },
  {
    path: '/cms/dqmanage/ratingsplideranswer',
    name: 'ratingsplider',
    component: resolve => require(['@/views/cmsPage/versionManage/ratingsplider/answerManage.vue'], resolve)
  },
  {
    path: '/cms/versionManage/preGBBCMark',
    name: 'preGBBCMark',
    component: resolve => require(['@/views/cmsPage/versionManage/preGBBCMark/index.vue'], resolve)
  },
  {
    path: '/cms/dqmanage/duibanconfirm',
    name: 'duibanconfirm',
    component: resolve => require(['@/views/cmsPage/versionManage/duibanconfirm/index.vue'], resolve)
  },
  {
    path: '/cms/dqmanage/duibanrecord',
    name: 'duibanrecord',
    component: resolve => require(['@/views/cmsPage/versionManage/duibanrecord/index.vue'], resolve)
  },
  {
    path: '/cms/versionManage/sampleStore',
    name: 'sampleStore',
    component: resolve => require(['@/views/cmsPage/versionManage/sampleStore/index.vue'], resolve)
  },
  {
    path: '/cms/versionManage/duibanorder',
    name: 'duibanorder',
    component: resolve => require(['@/views/cmsPage/versionManage/duibanorder/index.vue'], resolve)
  },
  {
    path: '/cms/dqmanage/duibanCoinTrain',
    name: 'duibanCoinTrain',
    component: resolve => require(['@/views/cmsPage/versionManage/duibanCoinTrain/index.vue'], resolve)
  },
  {
    path: '/cms/dqmanage/duibanbatch',
    name: 'duibanbatch',
    component: resolve => require(['@/views/cmsPage/versionManage/duibanBatch/index.vue'], resolve)
  },
  {
    path: '/cms/dqmanage/duibanCoinBadcase',
    name: 'duibanCoinBadcase',
    component: resolve => require(['@/views/cmsPage/versionManage/duibanCoinBadcase/index.vue'], resolve)
  },
  {
    path: '/cms/duiban/taskmanage',
    name: 'duibanTask',
    component: resolve => require(['@/views/cmsPage/versionManage/duibanTask/index.vue'], resolve)
  },
  {
    path: '/cms/versionManage/duibanquestionanswer',
    name: 'duibanquestionanswer',
    component: resolve => require(['@/views/cmsPage/versionManage/duibanquestionanswer/index.vue'], resolve)
  },
  {
    path: '/cms/crowdsource/normalversion',
    name: 'normalversion',
    component: resolve => require(['@/views/cmsPage/versionManage/versionAuthAbout/versionAuthByNormal/index.vue'], resolve)
  },
  {
    path: '/cms/crowdsource/professionalversion',
    name: 'professionalversion',
    component: resolve => require(['@/views/cmsPage/versionManage/versionAuthAbout/versionAuthByExpert/index.vue'], resolve)
  },
  {
    path: '/cms/crowdsource/ratingversion',
    name: 'ratingversionAuth',
    component: resolve => require(['@/views/cmsPage/versionManage/versionAuthAbout/versionAuthByRating/index.vue'], resolve)
  },
  {
    path: '/cms/duiban/kaiyuanspecial',
    name: 'kaiyuanspecial',
    component: resolve => require(['@/views/cmsPage/versionManage/kaiYuanCoinByBackManage/index.vue'], resolve)
  },
  {
    path: '/cms/duiban/kaiyuanfacerel',
    name: 'kaiyuanfacerel',
    component: resolve => require(['@/views/cmsPage/versionManage/kaiYuanCoinByFrontManage/index.vue'], resolve)
  },
  {
    path: '/cms/duiban/kaiyuanfacedata',
    name: 'kaiyuanfacedata',
    component: resolve => require(['@/views/cmsPage/versionManage/kaiYuanCoinByFrontDataManage/index.vue'], resolve)
  },
  {
    path: '/cms/versionManage/duibanquestionlist',
    name: 'duibanquestionlist',
    component: resolve => require(['@/views/cmsPage/versionManage/duibanQuestionList/index.vue'], resolve)
  },
  {
    path: '/cms/sample/duibanmanage',
    name: 'duibanmanage',
    component: resolve => require(['@/views/cmsPage/versionManage/sampleDuiBanManage/index.vue'], resolve)
  },
  {
    path: '/cms/duiban/recoModelLabel',
    name: 'recoModelLabel',
    component: resolve => require(['@/views/cmsPage/versionManage/duibanModelLabel/index.vue'], resolve)
  },
]
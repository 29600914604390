import axios from "axios";

import { message,Spin } from 'ant-design-vue';

Spin.setDefaultIndicator({
  indicator: h => {
    return <i class="anticon anticon-loading anticon-spin ant-spin-dot"></i>;
  },
});
let config = {

    timeout: 600000,
    // 每次请求携带cookie
    withCredentials: true,
}
const instance = axios.create(config)

instance.interceptors.request.use(function (config) {
    let token = localStorage.getItem('token')||''
    let proxy_url = process.env.VUE_APP_BASE_URL
    config.url = proxy_url+config.url;
    config.headers['dqAdmin-token'] = token;
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 2. 响应拦截
instance.interceptors.response.use(res => {
      if(res.status == 200){
        if (res.data.status) {
            const {status} = res.data
            if(res.data.status == 401){
                localStorage.removeItem('token')
                setTimeout(()=>location.reload(),500)
            }
            if(status == 'SUCCESS'||status == 200){
                return res.data
            }else if(status == '200'){
                return res.data
            }else{
                message.error(res.data.message||'响应异常')
                return res.data.data
            }
        } else {
            return res.data
        }
      } else{
          message.error('服务异常，请打开控制台查看详细信息')
          this.$loading.hide()
          return res;
      }
    },
    // 对于错误响应的处理
    err => {
        if (err.response.status === 401 || err.response.status === '401') {
            message.error('TOKEN过期')
            localStorage.removeItem('token')
            setTimeout(()=>location.reload(),500)
        } else {
            console.log(err, '错误')
            message.error('服务异常，请打开控制台查看详细信息', err)
            return err;
        }
        this.$loading.hide()
    }
);

export default instance

const mutations  = {
    setBreads(state){
        let {NavigationsData} = state
        const deep_set_key = function(array){
            if(!array||array.length<1) return
            const args = Array.from(arguments)
            const parent_key_array = args.length == 2 ? args[1] : [];//取出父级key对子集做递归做连接
            array.map((rows,index)=>{
                rows.keys_array = parent_key_array.concat([index])
                rows.key = rows.keys_array.join('-')
                if(rows.children&&rows.children.length>0){
                    deep_set_key(rows.children,rows.keys_array)
                }
            })
            return array
        }
        const result = deep_set_key(NavigationsData)
        NavigationsData = result
    },
    updateBread(state,payload){
        state.breadList = payload
    },
    updateMenu(state,payload){
        state.NavigationsData = payload
    },
    updateUserInfo(state,payload){
        state.userInfo = payload
    },
    /** 全局更新图片放大 */
    updateCheckMatch(state, payload) {
        state.checkMatch = payload
    }
}

export default mutations
export default [
    {
        path:'/cms/usershop/mannagelist',
        name:'mannagelist',
        component: resolve => require(['@/views/cmsPage/shopManage/shopInfoList/index.vue'], resolve)
    },
    {
        path:'/cms/usershop/penalizelist',
        name:'penalizelist',
        component: resolve => require(['@/views/cmsPage/shopManage/penalizeList/index.vue'], resolve)
    },
    {
        path:'/cms/usershop/templatelist',
        name:'templatelist',
        component: resolve => require(['@/views/cmsPage/shopManage/messageTemplateList/index.vue'], resolve)
    },
    {
        path:'/cms/usershop/pushlist',
        name:'pushlist',
        component: resolve => require(['@/views/cmsPage/shopManage/messagePushList/index.vue'], resolve)
    },
    {
        path:'/cms/usershop/senderlogistics',
        name:'senderlogistics',
        component: resolve => require(['@/views/cmsPage/shopManage/senderLogisticsList/index.vue'], resolve)
    },
]